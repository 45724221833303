import React from 'react'
import { Image, Link } from '@chakra-ui/react'
import gitIcon from '../assets/images/Git_icon.svg'
import pythonIcon from '../assets/images/python-seeklogo.com.svg'
import pytorchIcon from '../assets/images/PyTorch_logo_icon.svg'
import ccIcon from '../assets/images/ISO_C++_Logo.svg'
import bashIcon from '../assets/images/Bash_Logo_Colored.svg'
import cudaIcon from '../assets/images/cuda.svg'
import atlassianIcon from '../assets/images/atlassian.svg'
import pythonIconPng from '../assets/images/pythonLogo.png'

const boxSize = '20px'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a href="https://github.com/mhurler123/" className="icon fa-github">
            <span className="label">Github</span>
          </a>
        </li>
        <li>
          <a href="mailto:hurlerml@gmail.com" className="icon fa-envelope-o">
            <span className="label">Email</span>
          </a>
        </li>
        {/*
        <li>
          <img src={pythonIcon} className="image greyout" verticalAlign="bottom" width="20px"/>
        </li>
        <li>
          <Image src={pythonIconPng} className="image greyout" verticalAlign="bottom" boxSize={boxSize} objectFit="cover"/>
        </li>
        <li>
         <Image src={ccIcon} width="20px" className="image greyout" verticalAlign="bottom"></Image>
        </li>
        <li>
          <Image src={bashIcon} boxSize={boxSize} className="image greyout" verticalAlign="bottom"></Image>
        </li>
        <li>
          <Image src={pytorchIcon} boxSize={boxSize} className="image greyout" verticalAlign="bottom"></Image>
        </li>
        <li>
          <Image src={cudaIcon} boxSize={boxSize} className="image greyout" verticalAlign="bottom"></Image>
        </li>
        <li>
          <Image src={gitIcon} boxSize={boxSize} className="image greyout" verticalAlign="bottom"></Image>
        </li>
        <li>
          <Image src={atlassianIcon} boxSize={boxSize} className="image greyout" verticalAlign="bottom"></Image>
        </li>
        */}
      </ul>
      <ul className="copyright">
        <li>&copy; Marcel Hurler</li>
        <li><Link href="/impressum">Impressum</Link></li>
      </ul>
    </div>
  </div>
)

export default Footer

import React from 'react'
import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

const Header = () => (
  <header id="header">
    <div className="inner">
      <a href="#" className="image avatar">
        <img src={avatar} alt="" />
      </a>
      <h1>
        <strong>Ich bin Marcel</strong>,
      </h1>
      <h1>Freelancer im Bereich Machine Learning</h1>
      <br></br>

      <ul className="actions">
        <li>
          <br></br>
          <a href="mailto:hurlerml@gmail.com" className="button">
            Hire Me
          </a>
        </li>
      </ul>
    </div>
    <Footer />
  </header>
)

export default Header
